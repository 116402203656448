












































































































import {
  defineComponent,
  ref,
  computed,
  inject,
  watch
} from "@/composition-api";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiButton from "@/components/ui/UiButton.vue";
import store from "@/store";
import router from "@/router";
import useImageUpload from "@/composition/useImageUpload";
import useUpdateImageIdentifier from "@/composition/useUpdateImageIdentifier";
import UiInput from "@dpa-id-components/ui-input";
import UiTextArea from "@/components/ui/UiTextArea.vue";
import UiToggle from "@/components/ui/UiToggle.vue";
import UiIconDelete from "@/assets/vectors/icons/delete.svg?inline";
import { useResetFilterRules } from "@/components/nav/navigation.composition";
import useManagePlaylists from "@/composition/useManagePlaylists";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import AHDeletePlaylistDialog from "./AHDeletePlaylistDialog.vue";
import { deletePlaylist as playlistState } from "./playlistState";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiButton,
    UiInput,
    UiTextArea,
    UiToggle,
    UiIconDelete,
    UiTransitionOpacity,
    AHDeletePlaylistDialog
  },
  props: {
    playlistId: {
      type: String,
      required: true,
      default: undefined
    }
  },
  setup(props, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const {
      imageFile,
      imageUrl,
      imageName,
      handleSelectedImage
    } = useImageUpload();
    const managePlaylists = useManagePlaylists();

    const {
      handleImageIdentifierUpload,
      handleImageIdentifierDelete
    } = useUpdateImageIdentifier();

    const playlistId = computed(() => props.playlistId);
    const playlist = computed(() =>
      store.state.playlists.playlists.find(({ id }) => id === playlistId.value)
    );

    const playlistImageURL = computed(() => {
      return `${store.state.stageconfig.config.curatedSearchImageURL}/${playlist.value?.imageIdentifier}`;
    });

    const changedPlaylistImageURL = ref(playlistImageURL.value);
    const onClearSelectedImage = () => {
      imageFile.value = "";
      imageName.value = "";
      imageUrl.value = "";
      changedPlaylistImageURL.value = "";
    };

    const title = ref(playlist.value?.name || "");
    const description = ref(playlist.value?.description || "");

    const isPlaylistPublished = ref(playlist.value?.isPlaylistActive);

    const updateImageNameValue = computed(() => {
      return imageName ? imageName.value : playlist.value?.imageIdentifier;
    });

    const playlistImageValue = computed(() => {
      return !playlist.value?.imageIdentifier
        ? imageName.value
        : playlist.value?.imageIdentifier;
    });

    const updatePlaylist = async () => {
      const updatePayload = {
        id: playlist.value?.id,
        name: title.value,
        description: description.value,
        imageIdentifier: updateImageNameValue.value || playlistImageValue.value,
        isPlaylistActive: isPlaylistPublished.value
      };

      // upload ImageIdentifier
      await handleImageIdentifierUpload(imageFile, imageName);

      await managePlaylists.updatePlaylist(updatePayload);

      eventBus.$emit("show", {
        title: `Playlist aktualisiert`,
        icon: "checkmark"
      });

      emit("close");
    };

    const { resetFilterRules } = useResetFilterRules();

    const deletePlaylist = () => {
      playlistState.showDialog = true;
      playlistState.playlistId = playlist.value?.id;
    };

    const onPlaylistDeleted = ({ imageIdentifier, name }) => {
      if (imageIdentifier && name) {
        // delete imageIdentifier
        handleImageIdentifierDelete(ref(imageIdentifier));

        eventBus.$emit("show", {
          title: `Playlist "${name}" wurde gelöscht`,
          icon: "delete"
        });
      } else {
        eventBus.$emit("show", {
          title: `Playlist wurde gelöscht`,
          icon: "delete"
        });
      }

      if (!router.currentRoute.path.startsWith("/playlists")) {
        router.push({ path: "/playlists" });
      }

      resetFilterRules();

      emit("close");
    };

    watch(playlist, (val, deletedPlaylist) => {
      // NB: workaround for playlist deletion as event listening
      // does not work across portal-vue instances
      if (!val && !!deletedPlaylist) {
        const { imageIdentifier, name } = deletedPlaylist;
        onPlaylistDeleted({ imageIdentifier, name });
      }
    });

    const onDeleteDialogClose = () => {
      playlistState.showDialog = false;
      playlistState.playlistId = "";
    };

    return {
      playlist,
      title,
      description,
      imageUrl,
      playlistImageURL,
      handleSelectedImage,
      onClearSelectedImage,
      isPlaylistPublished,
      playlistState,
      deletePlaylist,
      updatePlaylist,
      changedPlaylistImageURL,
      onPlaylistDeleted,
      onDeleteDialogClose
    };
  }
});

import { ref, watch } from "@/composition-api";

export default () => {
  const imageFile = ref("");
  const imageUrl = ref("");
  const imageName = ref("");

  function handleSelectedImage(event) {
    if (event.target.files.length === 0) {
      imageFile.value = "";
      imageUrl.value = "";
      imageName.value = "";
      return;
    }
    imageFile.value = event.target.files[0];
    imageName.value = event.target.files[0].name.slice(0, -4);
  }

  watch(imageFile, (imageFile: any) => {
    if (!(imageFile instanceof File)) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.readAsDataURL(imageFile);

    fileReader.addEventListener("load", () => {
      imageUrl.value = fileReader.result as string;
    });
  });

  return { imageFile, imageUrl, imageName, handleSelectedImage };
};

import store from "@/store";
import useAuthentication from "./useAuthentication";

export default () => {
  const { validateAndRetrieveToken } = useAuthentication();

  const updatePlaylist = async updatePayload => {
    await store.dispatch("playlists/updateExistingPlaylist", {
      playlist: updatePayload,
      authJWT: validateAndRetrieveToken()
    });
  };

  const deletePlaylist = playlist => {
    store.dispatch("playlists/deleteExistingPlaylist", {
      playlist,
      authJWT: validateAndRetrieveToken()
    });
  };

  const removeAudioItemsFromPlaylist = ({ audioItems, playlist }) => {
    try {
      store.dispatch("playlists/removeAudioItemsFromPlaylist", {
        audioItems: audioItems.map(({ guid }) => guid),
        playlist,
        authJWT: validateAndRetrieveToken()
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return { updatePlaylist, deletePlaylist, removeAudioItemsFromPlaylist };
};

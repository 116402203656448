







































import { computed, defineComponent, inject } from "@vue/composition-api";
import useManagePlaylists from "@/composition/useManagePlaylists";
import UiDialog from "@/components/ui/UiDialog.vue";
import UiIconClose from "@/components/ui/UiIconClose.vue";
import UiTransitionOpacity from "@/components/ui/UiTransitionOpacity.vue";
import UiButton from "@/components/ui/UiButton.vue";
import store from "@/store";

export default defineComponent({
  components: {
    UiDialog,
    UiIconClose,
    UiTransitionOpacity,
    UiButton
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    playlistId: {
      type: String,
      required: true,
      default: undefined
    },
    portalTargetName: {
      type: String,
      default: "dialog"
    }
  },
  setup(props, { emit }) {
    const eventBus: Vue = inject("eventBus");
    const playlist = computed(() =>
      props.playlistId
        ? store.state.playlists.playlists.find(
            ({ id }) => id === props.playlistId
          )
        : undefined
    );
    const managePlaylists = useManagePlaylists();

    const deletePlaylist = () => {
      managePlaylists.deletePlaylist(playlist.value);

      eventBus.$emit("show", {
        title: `Playlist wurde gelöscht`,
        icon: "delete"
      });

      emit("close");
    };

    return { deletePlaylist, playlist };
  }
});

import { computed } from "@/composition-api";
import store from "@/store";
import useAuthentication from "@/composition/useAuthentication";
import router from "@/router";
import { defaultSort as sort } from "@/components/nav/config";
import useFetchAudioItems from "./useFetchAudioItems";
import orderBy from "lodash.orderby";

export default () => {
  const playlistsImageURL = computed(
    () => store.state.stageconfig.config.curatedSearchImageURL
  );
  const playlists = computed(() => {
    return store.state.playlists.playlists.map(
      ({
        name,
        imageIdentifier,
        description,
        updatedAt,
        id,
        isPlaylistActive,
        order
      }) => ({
        label: name,
        src: `${playlistsImageURL.value}/${imageIdentifier}`,
        id,
        description,
        updatedAt: new Date(updatedAt),
        isPlaylistActive,
        order
      })
    );
  });

  const publicPlaylist = computed(() => {
    return store.state.playlists.playlists
      .filter(it => it.isPlaylistActive)
      .map(
        ({
          name,
          imageIdentifier,
          description,
          updatedAt,
          id,
          isPlaylistActive,
          order
        }) => ({
          label: name,
          src: `${playlistsImageURL.value}/${imageIdentifier}`,
          id,
          description,
          updatedAt: new Date(updatedAt),
          isPlaylistActive,
          order
        })
      );
  });

  const fetchPlaylists = async (search = []) => {
    const { validateAndRetrieveToken: authJWT } = useAuthentication();
    return await store.dispatch("playlists/fetchPlaylists", {
      authJWT: authJWT(),
      ...(search.length ? { search } : {})
    });
  };

  const { fetchAudioitems } = useFetchAudioItems();
  const fetchPlaylistItems = guidItems => {
    const filterRules = [
      {
        type: "FILTER_GUID",
        values: guidItems
      }
    ];
    store.commit("filterRules/setFilterRules", { filterRules });
    fetchAudioitems({ hasNewFilterRules: true });
  };

  const orderPublicPlaylist = publicPlaylist => {
    return orderBy(publicPlaylist.value, ["order"], ["asc"]);
  };

  const onCardClick = id => {
    router.push({ path: "/audioitems", query: { sort, playlistId: id } });
  };

  return {
    playlists,
    onCardClick,
    publicPlaylist,
    fetchPlaylists,
    fetchPlaylistItems,
    orderPublicPlaylist
  };
};

<template>
  <!-- ui-text-area -->
  <div class="relative">
    <label
      :class="labelClassNames"
      :for="id"
      class="absolute px-1 transition-all duration-150 origin-left transform pointer-events-none select-none"
      >{{ label }}</label
    >
    <textarea
      :id="id"
      ref="input"
      :value="value"
      class="block w-full p-4 text-gray-900 placeholder-gray-400 rounded-none transition duration-150 border border-solid outline-none text-small h-40 hover:border-blue focus:border-blue focus:border-2"
      type="text"
      @input="updateValue($event.target.value)"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
  </div>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  data() {
    return {
      isFocused: false,
      id: `id-${uuidv4()}`
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * @returns {Boolean}
     */
    isFilled() {
      return this.value !== "";
    },
    /**
     * @returns {String}
     */
    labelClassNames() {
      if (this.isFocused) {
        return "text-blue translate-x-1 -translate-y-1/2 scale-75 bg-white";
      } else if (this.isFilled) {
        return "text-gray-500 translate-x-1 -translate-y-1/2 scale-75 bg-white";
      }
      return "text-gray-500 text-small translate-x-3 translate-y-4";
    }
  },
  methods: {
    updateValue: function(value) {
      this.$emit("input", value);
    }
  },
  mounted() {
    if (this.autofocus) {
      this.$refs.textarea.focus();
    }
  }
});
</script>
